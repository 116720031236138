import * as client_hooks from '../../../src/hooks/index.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95')
];

export const server_loads = [0,2,4,7,15,16,17,11,12,13,6];

export const dictionary = {
		"/(nav)": [~18,[2],[3]],
		"/(nav)/@[slug]": [19,[2,4],[3]],
		"/(nav)/@[slug]/posts": [20,[2,4],[3]],
		"/@[slug]/sessions": [~84],
		"/@[slug]/sessions/[slug]": [~85],
		"/(nav)/account": [~28,[2,7],[3]],
		"/(nav)/account/chat": [~29,[2,7],[3]],
		"/(nav)/account/chat/[id]": [~30,[2,7],[3]],
		"/(nav)/account/following": [~31,[2,7],[3]],
		"/(nav)/account/onboarding": [~32,[2,7],[3]],
		"/(nav)/account/sessions": [~35,[2,7],[3]],
		"/(nav)/account/sessions/create": [~36,[2,7],[3]],
		"/(nav)/account/session/[coachingId]": [~33,[2,7],[3]],
		"/(nav)/account/session/[coachingId]/[coachingSessionId]": [~34,[2,7],[3]],
		"/(nav)/account/settings": [37,[2,7,8],[3]],
		"/(nav)/account/settings/about": [~38,[2,7,8],[3]],
		"/(nav)/account/settings/achievements": [~39,[2,7,8],[3]],
		"/(nav)/account/settings/availability": [~40,[2,7,8],[3]],
		"/(nav)/account/settings/connected-accounts": [~41,[2,7,8],[3]],
		"/(nav)/account/settings/discounts": [~42,[2,7,8],[3]],
		"/(nav)/account/settings/faq": [~43,[2,7,8],[3]],
		"/(nav)/account/settings/groups": [~44,[2,7,8],[3]],
		"/(nav)/account/settings/notifications": [~45,[2,7,8],[3]],
		"/(nav)/account/settings/payments": [~46,[2,7,8],[3]],
		"/(nav)/account/settings/pro": [~47,[2,7,8],[3]],
		"/(nav)/account/settings/security": [~48,[2,7,8],[3]],
		"/(nav)/account/settings/sessions": [~49,[2,7,8],[3]],
		"/(nav)/account/settings/short-links": [~50,[2,7,8],[3]],
		"/(nav)/account/settings/subscriptions": [~51,[2,7,8],[3]],
		"/(nav)/account/settings/testimonials": [~52,[2,7,8],[3]],
		"/(nav)/account/studio": [~53,[2,7,9],[3]],
		"/(nav)/account/studio/customers": [~54,[2,7,9],[3]],
		"/(nav)/account/studio/customers/[id]": [~55,[2],[3]],
		"/(nav)/account/studio/discounts": [~56,[2,7,9],[3]],
		"/(nav)/account/studio/groups": [~57,[2,7,9],[3]],
		"/(nav)/account/studio/referrals": [~58,[2,7,9],[3]],
		"/(nav)/account/studio/sessions": [~59,[2,7,9],[3]],
		"/(nav)/account/studio/videos": [60,[2,7,9],[3]],
		"/(nav)/account/wallet": [~61,[2,7],[3]],
		"/auth/account/create": [86,[14]],
		"/auth/account/forgot-password": [87,[14]],
		"/auth/account/login": [88,[14]],
		"/auth/account/reset-password": [~89,[14]],
		"/auth/external/[...provider]": [90,[15]],
		"/checkout": [~91,[16]],
		"/feedback/[...board]": [92,[17]],
		"/gamerzclass": [~93],
		"/(nav)/groups": [~62,[2],[3]],
		"/(nav)/groups/create": [74,[2],[3]],
		"/(nav)/groups/mine": [~75,[2],[3]],
		"/(nav)/groups/[filter=groupsFilter]": [~63,[2],[3]],
		"/(nav)/groups/[slug]": [~64,[2],[3]],
		"/(nav)/groups/[slug]/about": [~65,[2,10],[3]],
		"/(nav)/groups/[slug]/members": [~66,[2,10],[3]],
		"/(nav)/groups/[slug]/settings": [67,[2,11],[3]],
		"/(nav)/groups/[slug]/settings/channels": [68,[2,11],[3]],
		"/(nav)/groups/[slug]/settings/details": [69,[2,11],[3]],
		"/(nav)/groups/[slug]/settings/discord-roles": [70,[2,11],[3]],
		"/(nav)/groups/[slug]/settings/tiers": [71,[2,11],[3]],
		"/(nav)/groups/[slug]/settings/visibility": [72,[2,11],[3]],
		"/(nav)/groups/[slug]/tiers": [73,[2],[3]],
		"/(nav)/posts/[id]": [~76,[2],[3]],
		"/pro": [94],
		"/(nav)/rules": [~77,[2],[3]],
		"/(nav)/sessions": [~78,[2,12],[3]],
		"/(nav)/sessions/mine": [~80,[2,12],[3]],
		"/(nav)/sessions/[filter]": [~79,[2,12],[3]],
		"/(nav)/tags/[tag]/[...path]": [~81,[2,13],[3]],
		"/test": [95],
		"/(nav)/the-first-annual-metafy-monthly-contest": [~82,[2],[3]],
		"/(nav)/updates": [~83,[2],[3]],
		"/(nav)/[game]": [~21,[2,5],[3]],
		"/(nav)/[game]/groups": [~22,[2,5],[3]],
		"/(nav)/[game]/groups/mine": [~24,[2,5],[3]],
		"/(nav)/[game]/groups/[filter=groupsFilter]": [~23,[2,5],[3]],
		"/(nav)/[game]/sessions": [~25,[2,5,6],[3]],
		"/(nav)/[game]/sessions/mine": [~27,[2,5,6],[3]],
		"/(nav)/[game]/sessions/[filter]": [~26,[2,5,6],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';